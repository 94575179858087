import * as React from "react";

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "./views/Home";
import NotFound from "./views/NotFound";
import { useWeb3Context } from "./hooks";
import Layout from "./layout";
// import BTest from "./views/Mint/BTest";
import Admin from "./views/Admin";
import USDCMint from "./views/Mint/USDC";
import Competition from "./views/Competition";
import Competition2 from "./views/Competition2";
import CreateComp from "./views/CreateComp";
import Comp from "./views/Comp";

import { useEffect, useState, useCallback } from "react";

export const App = () => {
  const { connect, provider, hasCachedProvider, chainID, connected } =
    useWeb3Context();
  const [walletChecked, setWalletChecked] = useState(false);

  useEffect(() => {
    if (hasCachedProvider()) {
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      setWalletChecked(true);
    }
  }, []);
  return (
    <>
      {walletChecked ? (
        <>
          <ToastContainer />
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/mint/btest" element={<BTest />} /> */}
              <Route path="/mint/usdc" element={<USDCMint />} />
              <Route path="/create" element={<CreateComp />} />
              <Route path="/competition" element={<Competition />} />
              <Route path="/competition2" element={<Competition2 />} />
              <Route path="/comp" element={<Comp />} />
              <Route path="/admin" element={<Admin />} />
              {/* 
              <Route path="/admin/first" element={<AdminFirst />} />
              <Route path="/admin/second" element={<AdminSecond />} />
              <Route path="/admin/third" element={<AdminThird />} />
              <Route path="/mint/first" element={<FirstSale />} />
              <Route path="/mint/owner" element={<OwnerMint />} />
              <Route path="/mint/second" element={<SecondSale />} />
              <Route path="/mint/third" element={<ThirdSale />} />
              <Route path="/admin/general" element={<AdminGeneral />} /> */}

              <Route path="/*" element={<NotFound />} />
            </Routes>
          </Layout>
        </>
      ) : (
        <>loading</>
      )}
    </>
  );
};

export default App;
