import { configureStore } from "@reduxjs/toolkit";

// import minteducer from "./slices/mint-slice";
import btestReducer from "./slices/btest-slice";
import usdcReducer from "./slices/usdc-slice";
// import messagesReducer from "./slices/messages-slice.ts";

const store = configureStore({
  reducer: {
    // mint: minteducer,
    // messages: messagesReducer,
    btest: btestReducer,
    usdc: usdcReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
