import {
  Box,
  Center,
  Flex,
  HStack,
  Link as ChakraLink,
  // Button,
  useColorModeValue as mode,
  // VisuallyHidden,
} from "@chakra-ui/react";

import { Link as RouterLink } from "react-router-dom";

import { Logo } from "./Logo";

import { WalletStatus } from "./WalletStatus";

export const NavHeader = () =>
  //   props: {
  //   // onClickMenu?: VoidFunction;
  //   // onClickSearch?: VoidFunction;
  // }
  {
    // const { onClickMenu, onClickSearch } = props;
    return (
      <Flex
        px="4"
        py="4"
        align="center"
        justify="space-between"
        // display={{ base: "flex", lg: "none" }}
      >
        <HStack spacing="3">
          <RouterLink to="/">
            <Logo h="4" />
          </RouterLink>
          <RouterLink to="/mint/usdc">USDC Mint</RouterLink>
          {/* <RouterLink to="/mint/btest">BTEST Mint</RouterLink> */}
          <RouterLink to="/competition">Competition</RouterLink>
          <RouterLink to="/competition2">Competition2</RouterLink>
          {/* <RouterLink to="/create">Create Competition</RouterLink> */}
          {/* <RouterLink to="/comp">Comp</RouterLink> */}
          <RouterLink to="/admin">Admin</RouterLink>
          {/* <RouterLink to="/mint/second">SecondSale</RouterLink>
          <RouterLink to="/mint/third">ThirdSale</RouterLink>
          <RouterLink to="/mint/owner">OwnerMint</RouterLink>|
          <RouterLink to="/admin">Admin</RouterLink> */}
          {/*
          <RouterLink to="/admin/first"> 1st sale setting</RouterLink>
          <RouterLink to="/admin/second"> 2nd sale setting </RouterLink>
          <RouterLink to="/admin/third"> 3rd sale setting</RouterLink>
          <RouterLink to="/admin/general"> general setting</RouterLink> */}
        </HStack>
        <HStack>
          <Center>
            <WalletStatus />
          </Center>
        </HStack>
      </Flex>
    );
  };

export default NavHeader;
