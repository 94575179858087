import {
  Box,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import * as React from "react";
import { Button } from "@chakra-ui/react";
import { useFormik } from "formik";
import { BigNumber } from "ethers";
// import { BigNumber } from "ethers";
// import ethers from "ethers";
import { toast } from "react-toastify";

import {
  DEFAULT_NETWORK,
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../constants";
import { useWeb3Context } from "../hooks";
import { useEffect, useState } from "react";
import { getBTESTContract, getCompetitionContract } from "../helpers/contracts";

export const Competition = () => {
  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    chainID,
    address,
    provider,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const [totalInvestment, setTotalInvestment] = useState("");
  const [depositLoading, setDepositLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);

  const [depositDisabled, setDepositDisabled] = useState(true);
  const [lastHash, setLastHash] = useState("");
  const [btestBalance, setBTESTBalance] = useState("");

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  useEffect(() => {
    const test = async () => {
      const con = getCompetitionContract(provider.getSigner());
      const bcon = getBTESTContract(provider.getSigner());
      const depositToken = await con.depositToken();

      if (address != null) {
        bcon.balanceOf(con.address).then((bal: BigNumber) => {
          setTotalInvestment(bal.toString());
        });
      }
      const bbal = await bcon.balanceOf(address);
      setBTESTBalance(bbal.toString());
      bcon.allowance(address, con.address).then((bal: BigNumber) => {
        //TODO should compare with the input
        if (bal.gte(BigNumber.from("9999999999999999999999"))) {
          setDepositDisabled(false);
        } else {
          setDepositDisabled(true);
        }
      });
    };
    test();
  }, [address, connected, lastHash]);

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const onDeposit = async (quantity: string) => {
    console.log("onDeposit");
    console.log("quantity: ", quantity);

    if (BigNumber.from(quantity).lte(BigNumber.from("0"))) {
      toast("you need to imput number");
      return;
    }
    try {
      console.log("address:", address);
      if (address != null) {
        console.log("start ");
        console.log("setDepositLoading");
        setDepositLoading(true);
        setDepositDisabled(true);

        const con = getCompetitionContract(provider.getSigner());
        console.log("con: ", con);
        // const bcon = getBTESTContract(provider.getSigner());
        const gas = await con.estimateGas.deposit(quantity);
        console.log("gas: ", gas.toString());
        const tx = await con.deposit(BigNumber.from(quantity).toString(), {
          gasLimit: gas,
        });
        toast(tx.hash);

        console.log("tx.hash:", tx.hash);

        const ret = await tx.wait();
        sleep(2000);
        setLastHash(tx.Hash);
        toast("end");
      }
    } catch (e: any) {
      toast(e.reason);
    }

    setDepositLoading(false);
  };
  const formixDeposit = useFormik({
    initialValues: {
      amount: "",
    },
    onSubmit: (values) => {
      console.log("onSubmit");
      console.log("values: ", values);
      onDeposit(values.amount);
    },
  });

  const onApprove = async () => {
    console.log("onApprove");
    console.log("address:", address);
    if (address != null) {
      try {
        console.log("setApproveLoading");
        setApproveLoading(true);
        console.log("start transaction1");
        const con = getCompetitionContract(provider.getSigner());
        console.log("con:", con);
        console.log("con.address:", con.address);
        const bcon = getBTESTContract(provider.getSigner());
        console.log("bcon:", bcon);
        // console.log("start aprove");
        const gas = await bcon.estimateGas.approve(
          con.address,
          "9999999999999999999999999999"
        );

        const tx = await bcon.approve(
          con.address,
          "9999999999999999999999999999",
          { gasLimit: gas }
        );

        toast(tx.hash);

        const ret = tx.wait();
        console.log(ret);
        sleep(2000);
        toast("end");
        setLastHash(tx.hash);
      } catch (e: any) {
        console.log(e);
        toast(e.reason);
      }
    }
    setApproveLoading(false);
  };

  const onClaim = async () => {
    console.log("onClaim");

    try {
      console.log("address:", address);
      if (address != null) {
        console.log("start ");
        console.log("setClaimLoading");
        setClaimLoading(true);

        const con = getCompetitionContract(provider.getSigner());
        console.log("con: ", con);
        // const bcon = getBTESTContract(provider.getSigner());
        const gas = await con.estimateGas.claim();
        console.log("gas: ", gas.toString());
        const tx = await con.claim({
          gasLimit: gas,
        });
        toast(tx.hash);

        console.log("tx.hash:", tx.hash);

        const ret = await tx.wait();
        sleep(2000);
        setLastHash(tx.Hash);
        toast("end");
      }
    } catch (e: any) {
      toast(e.reason);
    }
    setClaimLoading(false);
  };

  if (!isConnected) {
    return (
      <>
        <Button onClick={connect} colorScheme="blue">
          connect
        </Button>
      </>
    );
  }
  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    return (
      <>
        <Button onClick={checkWrongNetwork} colorScheme="red">
          switch network
        </Button>
      </>
    );
  }
  return (
    <>
      <div>
        <>
          <h2>Competition</h2>
          totalInvestment: {totalInvestment.toString()}
          <div>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={approveLoading}
              disabled={approveLoading}
              type="submit"
              onClick={onApprove}
            >
              Approve
            </Button>
          </div>
          <div>BTEST Balance: {btestBalance}</div>
          <div>
            <form onSubmit={formixDeposit.handleSubmit}>
              <NumberInput defaultValue={1} min={1}>
                <NumberInputField
                  name="amount"
                  onChange={formixDeposit.handleChange}
                  value={formixDeposit.values.amount}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <Button
                mt={4}
                colorScheme="teal"
                isLoading={depositLoading}
                type="submit"
                disabled={depositDisabled}
              >
                Deposit{" "}
              </Button>
            </form>
          </div>
          <div>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={claimLoading}
              disabled={claimLoading}
              type="submit"
              onClick={onClaim}
            >
              Claim
            </Button>
          </div>
        </>
      </div>{" "}
    </>
  );
};
export default Competition;
