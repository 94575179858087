import {
  Box,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import * as React from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import {
  CTESTAddress,
  BTESTAddress,
  FakeUSDCAddress,
  FakeDAIAddress,
  CompetitionAddress,
} from "../constants/addresses/";
import {
  getCompetitionContract,
  getCTESTContract,
  getFakeUSDCContract,
} from "../helpers/contracts";

import { useWeb3Context } from "../hooks";
import { useEffect, useState } from "react";
import {
  DEFAULT_NETWORK,
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../constants";
import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { usdcMint } from "../store/slices/usdc-slice";
import { useFormik } from "formik";
import { IReduxState } from "../store/slices/state.interface";
import { BigNumber } from "ethers";
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

export const Admin = () => {
  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    chainID,
    address,
    provider,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const [salesOn, setSalesOn] = useState(true);
  const [claimOn, setClaimOn] = useState(true);
  const [lastHash, setLastHash] = useState("");
  const [onSalesLoading, setOnSalesLoading] = useState(false);
  const [onClaimLoading, setOnClaimLoading] = useState(false);
  const [onSalesDisabled, setOnSalesDisabled] = useState(false);
  const [onClaimDisabled, setOnClaimDisabled] = useState(false);

  const contract = getCompetitionContract(provider.getSigner());

  const btnSalesOn = async () => {
    console.log(contract);
    try {
      setOnSalesLoading(true);
      setOnSalesDisabled(true);
      const gas = await contract.estimateGas.setSalesOn(!salesOn);
      console.log("gas:", gas.toString());
      const tx = await contract.setSalesOn(!salesOn, { gasLimit: gas });

      toast(tx.hash);
      console.log("tx.hash:", tx.hash);
      const ret = await tx.wait();
      sleep(2000);
      setLastHash(tx.hash);
      toast("end");
      console.log("ret", ret);
    } catch (e: any) {
      toast(e.reason);
    }
    setOnSalesLoading(false);
    setOnSalesDisabled(false);
  };
  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const btnClaimOn = async () => {
    // console.log(contract);
    // contract.setClaimOn(!claimOn).then((ret: any) => {
    //   console.log("setClaimOn", ret.toString());
    // });

    console.log(contract);
    try {
      setOnClaimLoading(true);
      setOnClaimDisabled(true);
      const gas = await contract.estimateGas.setClaimOn(!claimOn);
      console.log("gas:", gas.toString());
      const tx = await contract.setClaimOn(!claimOn, { gasLimit: gas });
      toast(tx.hash);
      console.log("tx.hash:", tx.hash);
      const ret = await tx.wait();

      sleep(2000);

      setLastHash(tx.hash);
      toast("end");
      console.log("ret", ret);
    } catch (e: any) {
      toast(e.reason);
    }
    setOnClaimLoading(false);
    setOnClaimDisabled(false);
  };

  useEffect(() => {
    const test = async () => {
      const ret1 = await contract.claimOn();
      setClaimOn(ret1);

      console.log("claimOn", ret1.toString());

      const ret2 = await contract.salesOn();
      setSalesOn(ret2);
      console.log("salesOn", ret2.toString());
    };
    test();
  }, [lastHash, address, connected]);

  if (!isConnected) {
    return (
      <>
        <Button onClick={connect} colorScheme="blue">
          connect
        </Button>
      </>
    );
  }
  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    return (
      <>
        <Button onClick={checkWrongNetwork} colorScheme="red">
          switch network
        </Button>
      </>
    );
  }
  return (
    <>
      <div>
        salesOn: {salesOn.toString()}
        <br />
        <Button
          isLoading={onSalesLoading}
          onClick={btnSalesOn}
          disabled={onSalesDisabled}
        >
          setSalesOn
        </Button>
        <hr />
        <br />
        claimOn: {claimOn.toString()}
        <br />
        <Button
          isLoading={onClaimLoading}
          onClick={btnClaimOn}
          disabled={onClaimDisabled}
        >
          setClaimOn
        </Button>
        <hr />
        <br /> {scannerURL}/address/{CompetitionAddress}
        <hr />
      </div>
    </>
  );
};
export default Admin;
