import React, { ReactElement } from "react";

import { Footer } from "../components/Footer";
import { NavHeader } from "../components/NavHeader";
import { TopBanner } from "../components/TopBanner";
export const Layout: React.FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <>
      <TopBanner />
      <NavHeader />
      <>{children}</>
      <Footer />
    </>
  );
};

export default Layout;
