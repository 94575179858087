import { DEFAULT_CHAIN_NETWORK_NAME } from "../blockchain";

import {
  CTESTAddress as FantomCTESTAddress,
  BTESTAddress as FantomBTESTAddress,
  FakeDAIAddress as FantomFakeDAIAddress,
  FakeUSDCAddress as FantomFakeUSDCAddress,
  CompetitionAddress as FantomCompetionAddress,
} from "./fantom";

import {
  CTESTAddress as FantomTestCTESTAddress,
  BTESTAddress as FantomTestBTESTAddress,
  FakeDAIAddress as FantomTestFakeDAIAddress,
  FakeUSDCAddress as FantomTestFakeUSDCAddress,
  CompetitionAddress as FantomTestCompetionAddress,
} from "./fantom_testnet";

import {
  CTESTAddress as MumbaiCTESTAddress,
  BTESTAddress as MumbaiBTESTAddress,
  FakeDAIAddress as MumbaiFakeDAIAddress,
  FakeUSDCAddress as MumbaiFakeUSDCAddress,
  CompetitionAddress as MumbaiCompetionAddress,
} from "./mumbai";

let networkname = DEFAULT_CHAIN_NETWORK_NAME;
let ctestAddress = "";
let btestAddress = "";
let fakeDAIAddress = "";
let fakeUSDCAddress = "";
let competitionAddress = "";

if (networkname == "fantom") {
  ctestAddress = FantomCTESTAddress;
  btestAddress = FantomBTESTAddress;
  fakeDAIAddress = FantomFakeDAIAddress;
  fakeUSDCAddress = FantomFakeUSDCAddress;
  competitionAddress = FantomCompetionAddress;
  // } else if (networkname == "bsctest") {
  // ctestAddress = BSCCTESTAddress;
  // btestAddress = BSCBTESTAddress;
}

if (networkname == "fantomtest") {
  ctestAddress = FantomTestCTESTAddress;
  btestAddress = FantomTestBTESTAddress;
  fakeDAIAddress = FantomTestFakeDAIAddress;
  fakeUSDCAddress = FantomTestFakeUSDCAddress;
  competitionAddress = FantomTestCompetionAddress;
  // } else if (networkname == "bsctest") {
  // ctestAddress = BSCCTESTAddress;
  // btestAddress = BSCBTESTAddress;
}
if (networkname == "mumbai") {
  ctestAddress = MumbaiCTESTAddress;
  btestAddress = MumbaiBTESTAddress;
  fakeDAIAddress = MumbaiFakeDAIAddress;
  fakeUSDCAddress = MumbaiFakeUSDCAddress;
  competitionAddress = MumbaiCompetionAddress;
  // } else if (networkname == "bsctest") {
  // ctestAddress = BSCCTESTAddress;
  // btestAddress = BSCBTESTAddress;
}

export const CTESTAddress = ctestAddress;
export const BTESTAddress = btestAddress;
export const FakeDAIAddress = fakeDAIAddress;
export const FakeUSDCAddress = fakeUSDCAddress;
export const CompetitionAddress = competitionAddress;
