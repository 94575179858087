import { Button, Box, Text } from "@chakra-ui/react";
import { useWeb3Context } from "../hooks";
import { trim, shorten } from "../helpers";
import { DEFAULT_NETWORK, USER_SELECTABLE_NETWORKS } from "../constants";
import { useEffect, useState } from "react";

export const WalletStatus = () => {
  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    address,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  let buttonText = "Connect Wallet";
  let clickFunc: any = connect;
  let buttonStyle = "blue";
  if (isConnected) {
    buttonText = "Disconnect";
    clickFunc = disconnect;
  }

  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    buttonText = "Wrong network";

    buttonStyle = "red";
    clickFunc = () => {
      checkWrongNetwork();
    };
  }

  return (
    <>
      {shorten(address)}
      <Box>
        <Button onClick={clickFunc} colorScheme={buttonStyle}>
          <Text> {buttonText}</Text>
        </Button>
      </Box>
    </>
  );
};
