import { ethers, constants } from "ethers";
import {
  createSlice,
  createSelector,
  createAsyncThunk,
  PayloadAction,
} from "@reduxjs/toolkit";
// import { useSnackbar } from "notistack";
import { toast } from "react-toastify";
import { NETWORKS } from "../../constants/blockchain";
import { DEFAULT_CHAIN_ID } from "../../constants/blockchain";

import {
  JsonRpcProvider,
  StaticJsonRpcProvider,
} from "@ethersproject/providers";

import { Networks } from "../../constants/blockchain";
import { messages } from "../../constants/messages";
import { BigNumber } from "ethers";
import { RootState } from "../store";
import { getCTESTContract, getFakeUSDCContract } from "../../helpers/contracts";
import { TransactionToastMessage } from "../../components/TransactionToastMessage";
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

interface IUSDCMint {
  quantity: number;
  to: string;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

const displayToast = async (msg: string, hash: string) => {
  toast(TransactionToastMessage(msg, hash));
};
export const usdcMint = createAsyncThunk(
  "mint/usdcMint",
  async ({ quantity, to, networkID, provider }: IUSDCMint, { dispatch }) => {
    console.log("mint/usdcMint");

    const con = getFakeUSDCContract(provider.getSigner());

    let mintTx;
    // let newAddress;
    // let newSymbol;

    try {
      console.log("start mintTx.wait ");
      console.log("con: ", con);

      const gas = await con.estimateGas.mint(to, quantity);
      console.log("gas: ", gas.toString());
      mintTx = await con.mint(to, quantity, { gasLimit: gas });
      // TODO: better to show toast notification.
      console.log("start mintTx.wait ");
      // const id = toast.loading("Sending transaction...");
      displayToast("Sending transaction...", mintTx.hash);
      console.log("mintTx.hash: ", mintTx.hash);
      try {
        await mintTx.wait();
        console.log("finish mintTx.wait ");
        console.log("mintTx.hash: ", mintTx.hash);
        displayToast("Minted successfully...", mintTx.hash);
        // toast.update(id, {
        //   render: "Minted successfully",
        //   type: "success",
        //   autoClose: 5000,
        //   isLoading: false,
        // });
      } catch (err: any) {
        console.log("err:", err);
        toast(err.reason);

        // toast.update(id, {
        //   render: "All is good",
        //   type: "error",
        //   isLoading: false,
        // });
        //   return metamaskErrorWrap(err, dispatch);
      } finally {
        console.log("finally");
        if (mintTx) {
          console.log("mintTx: ", mintTx);
          // dispatch(clearPendingTxn(bondTx.hash));
        }
      }

      // TODO: better to show toast notification again.

      // dispatch(success({ text: messages.tx_successfully_updated }));
    } catch (err: any) {
      console.log("err1:", err);
      displayToast("error1: ", err);
      //   return metamaskErrorWrap(err, dispatch);
    } finally {
      console.log("finally");
      if (mintTx) {
        console.log("mintTx: ", mintTx);
        // dispatch(clearPendingTxn(bondTx.hash));
      }
    }

    return {
      // newAddress: newAddress,
      // newSymbol: newSymbol,
    };
  }
);

export interface IUSDCMintSlice {
  loading: boolean;
  // newAddress: string;
  // newSymbol: string;
  // newTitle: string;
  // newDescription: string;
  [key: string]: any;
}
const initialState: IUSDCMintSlice = {
  loading: false,
  //   mintAddress: "",
  // newAddress: "",
  // newSymbol: "",
};

const usdcSlice = createSlice({
  name: "usdc",
  initialState,
  reducers: {
    // fetchMintSuccess(state, action) {
    //   state[action.payload.bond] = action.payload;
    // },
    // clearNewCreatedMint(state, action: PayloadAction<string>) {
    // clearNewCreatedMint(state) {
    //   // const target = state.find(x => x.txnHash === action.payload);
    //   // if (target) {
    //   //     state.splice(state.indexOf(target), 1);
    //   // }
    //   state.loading = false;
    //   state.newAddress = "";
    //   state.newSymbol = "";
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usdcMint.pending, (state) => {
        state.loading = true;
      })
      .addCase(usdcMint.fulfilled, (state, action) => {
        console.log("action.payload: ", action.payload);
        state.loading = false;
      })
      .addCase(usdcMint.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default usdcSlice.reducer;

// export const { clearNewCreatedMint } = mintSlice.actions;

const baseInfo = (state: RootState) => state.usdc;

export const getUSDCState = createSelector(baseInfo, (usdc) => usdc);
