import {
  Box,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import * as React from "react";

import { useNavigate } from "react-router-dom";
import {
  CTESTAddress,
  BTESTAddress,
  FakeUSDCAddress,
  FakeDAIAddress,
} from "../../constants/addresses/";
import { getCTESTContract, getFakeUSDCContract } from "../../helpers/contracts";

import { useWeb3Context } from "../../hooks";
import { useEffect, useState } from "react";
import {
  DEFAULT_NETWORK,
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../../constants";
import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { usdcMint } from "../../store/slices/usdc-slice";
import { useFormik } from "formik";
import { IReduxState } from "../../store/slices/state.interface";
import { BigNumber } from "ethers";
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

export const USDCMint = () => {
  // const navigate = useNavigate();

  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    chainID,
    address,
    provider,
    checkWrongNetwork,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const mintLoading = useSelector<IReduxState, boolean>(
    // (state) => state.jump.loading
    (state) => state.usdc.loading
  );

  const dispatch = useDispatch();

  const [totalSupply, setTotalSupply] = useState("");
  const [balance, setBalance] = useState("");
  const [lastHash, setLastHash] = useState("");

  useEffect(() => {
    const test = async () => {
      try {
        const con = getFakeUSDCContract(provider.getSigner());
        console.log("con:", con);
        const totalSupply = await con.totalSupply();
        //   console.log("totalSupply: ", totalSupply_);
        setTotalSupply(totalSupply.toString());

        console.log("address:", address);
        if (address != null) {
          console.log("balanceOf");
          const bal = await con.balanceOf(address);
          //   console.log("totalSupply: ", totalSupply_);
          setBalance(bal.toString());
        }
      } catch (e: any) {
        console.log("e: ", e);
      }
    };

    test();
  }, [mintLoading, address, connected]);

  const formikUSDCMint = useFormik({
    initialValues: {
      mintQuantity: "",
    },

    onSubmit: (values) => {
      console.log("onSubmit");
      console.log("mintQuantity: ", values.mintQuantity);
      const quantity = parseInt(values.mintQuantity);
      dispatch(
        //@ts-ignore
        usdcMint({
          quantity,
          to: address,
          networkID: chainID,
          provider,
        })
      );
    },
  });

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);
  if (!isConnected) {
    return (
      <>
        <Button onClick={connect} colorScheme="blue">
          connect
        </Button>
      </>
    );
  }
  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    return (
      <>
        <Button onClick={checkWrongNetwork} colorScheme="red">
          switch network
        </Button>
      </>
    );
  }

  return (
    <>
      totalSupply: {totalSupply}
      <br />
      balance: {balance}
      <form onSubmit={formikUSDCMint.handleSubmit}>
        <NumberInput defaultValue={1} min={1}>
          <NumberInputField
            name="mintQuantity"
            onChange={formikUSDCMint.handleChange}
            value={formikUSDCMint.values.mintQuantity}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <Button mt={4} colorScheme="teal" isLoading={mintLoading} type="submit">
          USDC Mint
        </Button>
      </form>
      <hr />
      <br />
      <br />
      <hr />
      <br /> {scannerURL}/address/{FakeUSDCAddress}
      <hr />
    </>
  );
};
export default USDCMint;
