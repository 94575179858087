import { Networks } from "../constants/blockchain";
import {
  CTESTAddress,
  BTESTAddress,
  FakeDAIAddress,
  FakeUSDCAddress,
  CompetitionAddress,
} from "../constants/addresses";
import {
  JsonRpcSigner,
  StaticJsonRpcProvider,
  JsonRpcProvider,
} from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";

import CTEST from "../abi/CTEST.json";
import BTEST from "../abi/BTEST.json";
import FakeDAI from "../abi/FakeDAI.json";
import FakeUSDC from "../abi/FakeUSDC.json";
import Competition from "../abi/Competition.json";
import { NETWORKS, DEFAULT_CHAIN_ID } from "../constants/blockchain";
const CTESTAbi = CTEST.abi;
const BTESTAbi = BTEST.abi;
const FakeDAIAbi = FakeDAI.abi;
const FakeUSDCAbi = FakeUSDC.abi;
const CompetitionAbi = Competition.abi;

export const getCompetitionContract = (
  provider?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  return getContract(CompetitionAddress, CompetitionAbi, provider);
};
export const getFakeUSDCContract = (
  provider?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  return getContract(FakeUSDCAddress, FakeUSDCAbi, provider);
};
export const getFakeDAIContract = (
  provider?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  return getContract(FakeDAIAddress, FakeDAIAbi, provider);
};

export const getBTESTContract = (
  provider?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  return getContract(BTESTAddress, BTESTAbi, provider);
};
export const getCTESTContract = (
  provider?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  return getContract(CTESTAddress, CTESTAbi, provider);
};

export const simpleRpcProvider = new JsonRpcProvider(
  NETWORKS[DEFAULT_CHAIN_ID]["rpcUrls"][0]
);

const getContract = (
  address: string,
  abi: any,
  signer?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  const signerOrProvider = signer ? signer : simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};
